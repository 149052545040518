<template>
  <section class="mx-3 mt-8 pb-6">
    <div
      class="d-flex align-center flex-column flex-sm-row justify-center justify-md-space-between my-6"
    >
      <div class="d-flex flex-column text-center">
        <v-btn
          v-if="showActionsSolicitud"
          class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          @click="HandlerFormAdminInsumoFtn"
        >
          Agregar insumo
        </v-btn>
      </div>
      <!-- <div class="d-flex flex-column flex-sm-row mb-3 mb-sm-0">
        <v-btn
          class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          outlined
          @click="UpdateListInsumosFtn"
        >
          Recargar lista
          <v-icon color="secondary"> mdi-sync </v-icon>
        </v-btn>
      </div> -->
    </div>

    <!-- --------- -->
    <data-table-component
      :headers="HEADERS_CONST"
      :items="list_insumos_st"
      mobile_breakpoint="1400"
      :tiene_paginacion="false"
      :inhabilitar_paginacion="true"
    >
      <!-- Slot estado -->
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          label
          :dark="item.estado.includes('Descartado')"
          :color="item.color"
        >
          {{ item.estado }}
        </v-chip>
      </template>
      <!-- Slot total -->
      <template v-slot:[`item.total`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.total)
        }}
      </template>
      <!-- Slot precio unitario -->
      <template v-slot:[`item.precio_unitario`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          }).format(item.precio_unitario)
        }}
      </template>
      <!-- Acciones de detalle -->
      <template v-slot:[`item.actions`]="{ item }">
        <!-- EDIT -->
        <v-tooltip top color="blueGrayMinsal" v-if="showEditOption(item)">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="secondary"
              class="mr-2"
              @click="HandlerFormAdminInsumoFtn(false, item)"
              :disabled="[3].includes(solicitud.paso.id) && haveRole('ROLE_TECNICO_UNIDAD')"
            >
              {{
                [1, 8].includes(solicitud?.paso?.id)
                  ? "mdi-pencil"
                  : "mdi-check-circle"
              }}
            </v-icon>
          </template>
          <span>
            {{
              [1, 8].includes(solicitud?.paso?.id)
                ? "Editar"
                : "Verificar"
            }}
            insumo
          </span>
        </v-tooltip>
        <!-- VIEW -->
        <!-- <v-tooltip top color="blueGrayMinsal">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="secondary"
              class="mr-2"
              @click="reviewInsumoFtn(item)"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Ver insumo</span>
        </v-tooltip> -->
        <!-- DELETE -->
        <v-tooltip top color="blueGrayMinsal" v-if="showDeleteOption(item)">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="secondary"
              class="mr-2"
              @click="openModalDeleteInsumoFtn(item)"
            >
              mdi-trash-can
            </v-icon>
          </template>
          <span>Eliminar insumo</span>
        </v-tooltip>
      </template>
    </data-table-component>

    <v-dialog persistent max-width="500" v-model="show_modal_insumo_detail_st">
      <v-card>
        <v-toolbar color="primary" dark>
          Detalle de información del insumo
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-1">
            <v-col cols="12" md="6" class="d-flex flex-column">
              <span> Código de OBS: </span>
              <span class="text-body-1 secondary--text">
                {{ insumoView?.info_obs_var?.codigo }}
              </span>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <span> Nombre de OBS: </span>
              <span class="text-body-1 secondary--text">
                {{ insumoView?.info_obs_var?.nombre }}
              </span>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <span> Unidad de medida: </span>
              <span class="text-body-1 secondary--text">
                {{ insumoView?.unidad_medida }}
              </span>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <span> Cantidad: </span>
              <span class="text-body-1 secondary--text">
                {{ insumoView?.cantidad }}
              </span>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <span> Precio unitario: </span>
              <span class="text-body-1 secondary--text">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  }).format(insumoView?.precio_unitario)
                }}
              </span>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <span>Especificación: </span>
              <span class="text-body-1 secondary--text">
                {{ insumoView?.observacion }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end pb-4">
          <v-row class="display-flex justify-end">
            <v-col cols="12" sm="4">
              <v-btn
                block
                class="text-capitalize"
                outlined
                @click="ResetShowModalInsumoDetailMt"
              >
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <form-admin-insumo-component
      ref="formAdminInsumo"
      :create-prop="form_create"
      :detail-prop="insumo_selected"
      @addItem="manejarCreacionInsumo"
      @updateItem="manejarEdicionInsumo"
      @changeItem="manejarCambioInsumo"
    />

    <confirmation-dialog-component
      :show="show_dialog_delete_var"
      title="¿Deseas eliminar el insumo?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="
        (show_dialog_delete_var = false), (id_insumo_seleccionado_var = null)
      "
      @confirm="deleteInsumoFtn"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

// importacion de componentes locales
import FormAdminInsumoComponent from "./FormAdminInsumoPTComponent.vue";

// importaciones de componentes globales
import DataTableComponent from "@/components/DataTableComponent.vue";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
    FormAdminInsumoComponent,
  },
  data: () => ({
    HEADERS_CONST: [
      {
        align: "center",
        text: "No.",
        value: "num",
      },
      {
        align: "center",
        text: "OBS",
        value: "obs",
      },
      {
        align: "center",
        text: "Especificación",
        value: "observacion",
      },
      {
        align: "center",
        text: "Unidad de medida",
        value: "unidad_medida",
      },
      {
        align: "center",
        text: "Cantidad solicitada",
        value: "solicitud_compra_detalle_obs[0].cantidad",
      },
      {
        align: "center",
        text: "Total ($)",
        value: "total",
      },
      {
        align: "center",
        text: "Precio unitario ($)",
        value: "precio_unitario",
      },
      {
        align: "center",
        text: "Estado",
        value: "estado",
      },
      {
        align: "center",
        text: "Acciones",
        value: "actions",
      },
    ],
    id_insumo_seleccionado_var: null,
    show_dialog_delete_var: false,
    insumoView: {
      info_obs_var: null,
      unidad_medida: null,
      cantidad: null,
      precio_unitario: null,
      observacion: null,
    },
    insumo_selected: null,
    form_create: true,
    timer_list: null,
  }),
  computed: {
    ...mapState("SolicitudCompraStore/ProcesoTradicionalStore", [
      "id_proceso_compra_st",
      "list_insumos_st",
      "show_modal_insumo_detail_st",
      "solicitud",
    ]),
    ...mapState(["userInfo"]),
    showActionsSolicitud() {
      if (this.solicitud?.paso?.id == 2) {
        if (this.haveRole("ROLE_JEFE_UNIDAD")) {
          return this.solicitud?.usuario?.id == this.userInfo?.user?.id;
        }
      }
      return (
        (this.haveRole("ROLE_TECNICO_UNIDAD") ||
          this.haveRole("ROLE_JEFE_UNIDAD")) &&
        (this.solicitud?.paso == null ||
          this.solicitud?.paso?.id == 1 ||
          this.solicitud?.paso?.id == 3 ||
          this.solicitud?.paso?.id == 8)
      );
    },
  },
  methods: {
    ...mapMutations("SolicitudCompraStore/ProcesoTradicionalStore", [
      "SetShowModalInsumoDetailMt",
      "ResetShowModalInsumoDetailMt",
      "RemoveItemListInsumosByIndexMt",
      "SetItemListInsumosMt",
      "UpdateItemListInsumosMt",
      "ChangeIdEstadoInsumoMt"
    ]),
    ...mapActions("SolicitudCompraStore/ProcesoTradicionalStore", [
      "FetchSolicitudesNecesidadesAc",
    ]),
    HandlerFormAdminInsumoFtn(create_param = true, item_param) {
      if (create_param) {
        this.$refs.formAdminInsumo.show_dialog = true;
        this.form_create = true;
      } else {
        this.$refs.formAdminInsumo.show_dialog = true;
        this.form_create = false;
        this.insumo_selected = item_param;
        this.$refs.formAdminInsumo.FetchDetailInsumo(item_param.id);
      }
    },
    UpdateListInsumosFtn() {
      this.FetchSolicitudesNecesidadesAc(this.$route.params.id_solicitud);
    },
    editInsumoFtn(item) {
      this.$router.push({
        name: "editar-insumo-proceso-tradicional",
        query: { id_insumo: item.id },
      });
    },
    openModalDeleteInsumoFtn(item) {
      this.show_dialog_delete_var = true;
      this.id_insumo_seleccionado_var = item.id;
    },
    async deleteInsumoFtn() {
      const { status } =
        await this.services.SolicitudCompra.deleteInsumoProcesoCompra(
          this.$route.params.id_solicitud,
          this.id_insumo_seleccionado_var
        );

      if (status === 200) {
        this.RemoveItemListInsumosByIndexMt(this.id_insumo_seleccionado_var);
        this.temporalAlert({
          message: "Insumo eliminado correctamente",
          show: true,
          type: "success",
        });
      } else {
        this.temporalAlert({
          message: "Error al eliminar el insumo",
          show: true,
          type: "error",
        });
      }

      // reseteo de variables
      this.show_dialog_delete_var = false;
      this.id_insumo_seleccionado_var = null;
    },
    reviewInsumoFtn(item) {
      this.id_insumo_seleccionado_var = item.id;
      this.FetchDetailInsumo();
    },
    async FetchDetailInsumo() {
      const response =
        await this.services.SolicitudCompra.getDetailInsumoSolicitudCompra(
          this.$route.params.id_solicitud,
          this.id_insumo_seleccionado_var
        );
      if (response.status === 200) {
        this.insumoView.info_obs_var = response.data.detalle_obs;
        this.insumoView.unidad_medida = response.data.unidad_medida;
        this.insumoView.cantidad =
          response.data.solicitud_compra_detalle_obs[0].cantidad;
        this.insumoView.precio_unitario = response.data.precio_unitario;
        this.insumoView.observacion = response.data.observacion;
        this.SetShowModalInsumoDetailMt(true);
      }

    },
    showEditOption(item) {
      const estadoSolicitud = this.solicitud?.paso?.id;
      const estadoItemValido = [1].includes(item.id_estado);
      const estadoSolicitudValido = [1, 8].includes(this.solicitud?.paso?.id);

      if (this.haveRole('ROLE_JEFE_UNIDAD')) return estadoSolicitud !== 3; 

      if (this.haveRole("ROLE_TECNICO_UNIDAD")) return estadoItemValido && estadoSolicitudValido;

      return false;
    },
    showDeleteOption(item) {
      return (
        this.userInfo?.user?.id == this.solicitud?.usuario?.id &&
        item.id_estado == 1 &&
        this.solicitud?.paso?.id === 1
      );
    },
    // TODO: usar las actions de vuex cuando los endpoints retornen el response correcto
    manejarCreacionInsumo() {
      this.UpdateListInsumosFtn();
      // SetItemListInsumosMt();
    },
    manejarEdicionInsumo() {
      this.UpdateListInsumosFtn();
      // UpdateItemListInsumosMt();
    },
    manejarCambioInsumo() {
      this.UpdateListInsumosFtn();
      // ChangeIdEstadoInsumoMt();
    }
  },
};
</script>
