<template>
  <section class="mt-8">
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn v-if="showActionAdd" color="secondary" @click="addDoc = true"
          ><v-icon color="white">mdi-plus</v-icon> documento de
          especificación</v-btn
        >
      </v-col>
    </v-row>

    <DataTableComponent
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="documentos"
      @paginar="paginate"
      :total_registros="totalRows"
      class="my-6"
    >
      <template #[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              fab
              @click="downloadFile(item.id)"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span> Descargar </span>
        </v-tooltip>

        <v-tooltip right v-if="showActionAdd">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              fab
              @click="
                (confirmDeleteEspecificacion = true), (docSelected = item.id)
              "
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </DataTableComponent>

    <ConfirmationDialog
      :show="confirmDeleteEspecificacion"
      title="¿Desea eliminar el documento de especificación?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Confirmar"
      @close="(confirmDeleteEspecificacion = false), (docSelected = null)"
      @confirm="deleteFile()"
      :showLoading="isLoading"
    />

    <v-dialog persistent max-width="800" v-model="addDoc">
      <v-card>
        <v-card-title class="text-h5">
          Agregar documento de especificación
        </v-card-title>
        <v-card-text class="pt-8">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="infoDoc.nombre"
                outlined
                label="Nombre del archivo *"
                @input="$v.infoDoc.nombre.$touch()"
                @blur="$v.infoDoc.nombre.$touch()"
                :error-messages="nombreArchivoError"
                clearable
                maxlength="75"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input
                v-model="infoDoc.archivo"
                outlined
                prepend-icon=""
                placeholder="Adjuntar archivo"
                label="Archivo*"
                @input="$v.infoDoc.archivo.$touch()"
                @blur="$v.infoDoc.archivo.$touch()"
                :error-messages="archivoError"
                prepend-inner-icon="mdi-paperclip"
                accept="application/pdf"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row class="display-flex justify-center">
            <v-col cols="12" sm="4">
              <v-btn
                block
                outlined
                color="secondary"
                v-if="!isLoading"
                @click="addDoc = false"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                block
                color="secondary"
                :loading="isLoading"
                @click="addDocumento"
                >Guardar archivo</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { helpers, required } from "vuelidate/lib/validators";
import DataTableComponent from "../../../../components/DataTableComponent.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";

const txtField = helpers.regex(
  "txtArea",
  /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "DocumentosEspecificacionComponent",
  components: {
    DataTableComponent,
    ConfirmationDialog,
  },
  data: () => ({
    totalRows: 0,
    page: 1,
    perPage: 10,
    documentos: [],
    headers: [
      {
        text: "Documento",
        align: "start",
        value: "nombre",
        width: "80%",
        sortable: false,
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
    isLoading: false,
    docSelected: null,
    confirmDeleteEspecificacion: false,
    addDoc: false,
    infoDoc: {
      nombre: null,
      archivo: null,
    },
  }),
  validations: {
    infoDoc: {
      nombre: {
        required,
        txtField,
      },
      archivo: {
        required,
        fileTypeValid,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
  },
  computed: {
    ...mapState("agregarInsumo", ["id_solicitud"]),
    ...mapState("SolicitudCompraStore/ProcesoTradicionalStore", ["solicitud"]),
    ...mapState(['institucionesUsuario']),
    ...mapState(["userInfo"]),
    nombreArchivoError() {
      const errors = [];
      if (!this.$v.infoDoc.nombre.$dirty) return errors;
      !this.$v.infoDoc.nombre.required &&
        errors.push("El nombre del archivo es requerido");
      !this.$v.infoDoc.nombre.txtField &&
        errors.push(
          "El nombre del archivo no es válido, solo se permiten letras, números y guiones"
        );
      return errors;
    },
    archivoError() {
      const errors = [];
      if (!this.$v.infoDoc.archivo.$dirty) return errors;
      !this.$v.infoDoc.archivo.required &&
        errors.push("El archivo es requerido");
      !this.$v.infoDoc.archivo.fileTypeValid &&
        errors.push("El archivo debe ser de tipo PDF");
      !this.$v.infoDoc.archivo.fileNameValid &&
        errors.push("El nombre del archivo no es válido");
      !this.$v.infoDoc.archivo.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          

      return errors;
    },
    showActionAdd() {
      if (this.solicitud && this.solicitud?.paso?.id == 2) {
        if (this.haveRole("ROLE_JEFE_UNIDAD")) {
          // const idsInstitucionesUsuario = this.institucionesUsuario.map((i) => i.id);
          // const institucionProcesoCompraPac = this.solicitud.proceso_compra_pac.id_institucion;
          
          // return idsInstitucionesUsuario.includes(institucionProcesoCompraPac);

          //Fixeando para quitar validacion y dejar middleware de validarEspecificaProcesoCompra
          //Validacion para solo dejar pasar el boton sin afectar lo ya existente
          const idsInstitucionesUsuario = this.institucionesUsuario.map((i) => i.id);
          return idsInstitucionesUsuario;
        }
      }

      return (this.haveRole("ROLE_TECNICO_UNIDAD") ||
        this.haveRole("ROLE_JEFE_UNIDAD")) &&
        (this.solicitud?.paso == null ||
          this.solicitud?.paso?.id == 1 ||
          this.solicitud?.paso?.id == 8)
        ? true
        : false;
    },
  },
  methods: {
    async addDocumento() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("nombre_documento", this.infoDoc.nombre);
        formData.append("documento", this.infoDoc.archivo);
        const response =
          await this.services.SolicitudCompra.addDocEspecificacion(
            this.$route.params.id_solicitud,
            formData
          );
        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: response.data.message,
          });
          this.infoDoc = {
            nombre: null,
            archivo: null,
          };
          this.$v.$reset();
          this.getDocumentosEspecificaciones();
          this.addDoc = false;
        }
        this.isLoading = false;
      }
    },
    async getDocumentosEspecificaciones() {

      const { status, data, headers } =
        await this.services.SolicitudCompra.getDocsEspecificacion(
          this.$route.params.id_solicitud,
          {
            page: this.page,
            per_page: this.perPage,
          }
        );
      if (status == 200) {
        this.documentos = data;
        this.totalRows = Number(headers.total_rows);
        this.perPage = Number(headers.per_page);
        this.page = Number(headers.page);
      }

    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getDocumentosEspecificaciones();
    },
    async downloadFile(idDoc) {
      try {

        const response =
          await this.services.SolicitudCompra.downloadDocEspecificacion(
            this.id_solicitud,
            idDoc
          );
        if (response) {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          const file = new File([blob], "documento.pdf", {
            type: response.headers["content-type"],
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = "";
          link.click();
        }

      } catch (e) {

        this.temporalAlert({
          type: "error",
          show: true,
          message: "No se ha podido descargar el documento de especificación",
        });
      }
    },
    async deleteFile() {
      this.isLoading = true;
      const response =
        await this.services.SolicitudCompra.deleteDocEspecificacion(
          this.id_solicitud,
          this.docSelected
        );

      if (response.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response.data.message,
        });

        this.getDocumentosEspecificaciones();
      }
      this.confirmDeleteEspecificacion = false;
      this.isLoading = false;
    },
  },
  watch: {
    addDoc(value) {
      if (!value) {
        this.infoDoc = {
          nombre: null,
          archivo: null,
        };
        this.$v.$reset();
      }
    },
  },
  created() {
    this.getDocumentosEspecificaciones();
  },
};
</script>
<style scoped>
</style>