<template>
  <v-dialog
    v-model="show_dialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="bgMinsal">
      <v-card-title class="pt-6 pb-12 d-flex justify-center">
        <span class="secondary--text text-h4">
          Formulario de administración de insumo
        </span>
      </v-card-title>
      <v-card-text>
        <v-row v-if="createProp">
          <!-- <v-col cols="12" md="6" lg="4" v-if="false">
            <v-autocomplete
              label="Tipo de OBS *"
              placeholder="Seleccione el tipo de OBS"
              outlined
              v-model="tipo_obs_var"
              :items="ctl_tipo_obs_var"
              item-text="nombre"
              item-value="id"
              :error-messages="obsError"
              @blur="$v.tipo_obs_var.$touch"
              @change="
                FetchCtlSegmObjFtn(),
                  (segm_obj_var = null),
                  (producto_var = null)
              "
              clearable
            />
          </v-col> -->
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              :disabled="tipo_obs_var === null"
              label="Segmento *"
              placeholder="Seleccione un segmento"
              outlined
              :loading="loadingSegmento"
              v-model="segm_obj_var"
              :items="ctl_segm_obj_var"
              item-text="nombre"
              item-value="id"
              :error-messages="segmObjError"
              @blur="$v.segm_obj_var.$touch"
              @change="FetchCtlProductoFtn(), (producto_var = null)"
              clearable
              return-object
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              :disabled="segm_obj_var === null"
              label="Producto"
              placeholder="Seleccione el producto"
              outlined
              :loading="loadingOBS"
              v-model="producto_var"
              :items="ctl_producto_var"
              item-text="nombre"
              item-value="id"
              :error-messages="productoError"
              @blur="$v.producto_var.$touch"
              clearable
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col
            cols="12"
            class="d-flex flex-column flex-sm-row justify-center"
          >
            <div class="px-6">
              <span class="text-h6" v-text="'Código de OBS: '" />
              <span class="text-h6" v-text="info_obs_var.codigo" />
            </div>
            <div class="px-6">
              <span class="text-h6" v-text="'Nombre de OBS: '" />
              <span class="text-h6" v-text="info_obs_var.nombre" />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              label="Unidad de medida *"
              placeholder="Seleccione la unidad de medida"
              outlined
              v-model="unidad_med_var"
              :items="ctl_unidad_med_var"
              item-text="nombre"
              item-value="id"
              :error-messages="unidadMedError"
              @blur="$v.unidad_med_var.$touch"
              clearable
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Cantidad *"
              placeholder="Ingrese la cantidad"
              outlined
              v-model="cantidad_var"
              :error-messages="cantidadError"
              @blur="$v.cantidad_var.$touch"
              @input="$v.cantidad_var.$touch, setTotalPrecioCantidadFtn()"
              clearable
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <money-text-field-component
              ref="money_txt_ref"
              label="Precio unitario *"
              placeholder="Ingrese el precio unitario"
              outlined
              v-model="precio_unit_var"
              :decimals="8"
              :error-messages="precioUnitError"
              @blur="$v.precio_unit_var.$touch"
              @input="$v.precio_unit_var.$touch, setTotalPrecioCantidadFtn()"
              clearable
            />

            <p v-if="total_precio_var > 0">
              <b>Total:</b>
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumSignificantDigits: 8,
                }).format(total_precio_var)
              }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-textarea
            class="mx-3 mt-3"
            label="Especificación *"
            placeholder="Ingrese la especificación del insumo"
            outlined
            auto-grow
            v-model="especificacion_var"
            :error-messages="especificacionError"
            @blur="$v.especificacion_var.$touch"
            @input="$v.especificacion_var.$touch"
            clearable
            counter="8000"
            maxlength="8000"
          />
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center justify-sm-start">
        <v-btn
          class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          outlined
          @click="show_dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="optionsByProfile == 1"
          class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          dark
          :loading="loadingSaveBtn"
          :disabled="loadingSaveBtn"
          @click="handlerFunctionsFtn"
        >
          {{ createProp ? "Guardar" : "Actualizar" }}
        </v-btn>
        <v-btn
          v-if="optionsByProfile == 2"
          class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="error"
          dark
          outlined
          @click="rejectInsumo"
        >
          <v-icon color="error">mdi-close</v-icon> Rechazar insumo
        </v-btn>
        <v-btn
          v-if="optionsByProfile == 2"
          class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          @click="allowInsumo"
        >
          <v-icon color="white">mdi-check</v-icon> Aprobar y actualizar insumo
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// importacion de librerias
import {
  required,
  requiredIf,
  decimal,
  helpers,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";

// importacion de componentes globales
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  name: "FormAdminInsumoPTComponent",

  props: {
    createProp: {
      type: Boolean,
      default: false,
    },

    detailProp: {
      type: Object,
      default: {},
    },
  },

  validations: {
    // required if por parametro de route
    tipo_obs_var: {
      required: requiredIf(function () {
        return this.createProp;
      }),
    },
    segm_obj_var: {
      required: requiredIf(function () {
        return this.createProp;
      }),
    },
    producto_var: {
      required: requiredIf(function () {
        return this.createProp;
      }),
    },

    unidad_med_var: { required },
    cantidad_var: { required, decimal },
    precio_unit_var: { required },
    especificacion_var: { required, txtArea },
  },

  components: {
    MoneyTextFieldComponent,
  },

  data: () => ({
    show_dialog: false,
    SNAPSHOT_CONST: {},

    // variables
    tipo_obs_var: 1,
    segm_obj_var: null,
    producto_var: null,
    unidad_med_var: null,
    cantidad_var: null,
    precio_unit_var: null,
    especificacion_var: null,

    // catalogos
    ctl_tipo_obs_var: [],
    ctl_segm_obj_var: [],
    ctl_producto_var: [],
    ctl_unidad_med_var: [],

    loadingSaveBtn: false,
    loadingSegmento: false,
    loadingOBS: false,

    info_obs_var: {},
    id_detalle_var: null,
    id_estado_insumo: null,
    total_precio_var: 0,
  }),

  computed: {
    ...mapState("SolicitudCompraStore/ProcesoTradicionalStore", [
      "id_proceso_compra_st",
    ]),

    optionsByProfile() {
      let typeOptions = 0;
      if (
        this.haveRole("ROLE_TECNICO_UNIDAD") &&
        (this.createProp || !this.createProp)
      )
        typeOptions = 1; // Permite agregar nuevo insumo
      if (this.haveRole("ROLE_JEFE_UNIDAD") && this.createProp) typeOptions = 1; // Permite agregar nuevo insumo
      if (this.haveRole("ROLE_JEFE_UNIDAD") && !this.createProp)
        typeOptions = 2; // Permite aprobar o denegar insumo

      return typeOptions;
    },

    obsError() {
      const errors = [];
      if (!this.$v.tipo_obs_var.$dirty) return errors;
      !this.$v.tipo_obs_var.required && errors.push("Tipo de OBS es requerido");
      return errors;
    },
    segmObjError() {
      const errors = [];
      if (!this.$v.segm_obj_var.$dirty) return errors;
      !this.$v.segm_obj_var.required &&
        errors.push("Segmento / Objeto específico de gasto es requerido");
      return errors;
    },
    productoError() {
      const errors = [];
      if (!this.$v.producto_var.$dirty) return errors;
      !this.$v.producto_var.required && errors.push("Producto es requerido");
      return errors;
    },
    unidadMedError() {
      const errors = [];
      if (!this.$v.unidad_med_var.$dirty) return errors;
      !this.$v.unidad_med_var.required &&
        errors.push("Unidad de medida es requerido");
      return errors;
    },
    cantidadError() {
      const errors = [];
      if (!this.$v.cantidad_var.$dirty) return errors;
      !this.$v.cantidad_var.required && errors.push("Cantidad es requerido");
      !this.$v.cantidad_var.decimal &&
        errors.push("Cantidad debe ser numérico");
      return errors;
    },
    precioUnitError() {
      const errors = [];
      if (!this.$v.precio_unit_var.$dirty) return errors;
      !this.$v.precio_unit_var.required &&
        errors.push("Precio unitario es requerido");
      return errors;
    },
    especificacionError() {
      const errors = [];
      if (!this.$v.especificacion_var.$dirty) return errors;
      !this.$v.especificacion_var.required &&
        errors.push("Especificación es requerido");
      !this.$v.especificacion_var.txtArea &&
        errors.push("Caracteres especiales no permitidos");
      return errors;
    },
  },

  methods: {
    // funciones regulares
    setTotalPrecioCantidadFtn() {
      this.total_precio_var = this.cantidad_var * this.precio_unit_var;
    },

    CloseModalFtn() {
      this.segm_obj_var = null;
      this.producto_var = null;
      this.unidad_med_var = null;
      this.cantidad_var = null;
      this.precio_unit_var = null;
      this.$refs.money_txt_ref.clear();
      this.especificacion_var = null;
      this.show_dialog = false;
      this.$v.$reset();
    },

    handlerFunctionsFtn() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.temporalAlert({
          message: "Ingresar todos los datos requeridos",
          show: true,
          type: "error",
        });
      } else {
        if (this.createProp) {
          this.addInsumoFtn();
        } else {
          this.updateInsumoFtn();
        }
      }
    },

    // funciones asincronas

    // peticiones de catalogo
    /**
     * @deprecated
     */
    async FetchCtlTipoObsFtn() {
      const response = await this.services.Obs.getObsCategoriaList();
      if (response.status === 200) {
        this.ctl_tipo_obs_var = response.data;
      }
    },

    async FetchCtlSegmObjFtn() {
      this.loadingSegmento = true;
      const response = await this.services.SolicitudCompra.getCtlSegmento(
        this.id_proceso_compra_st,
        { id_tipo_obs: this.tipo_obs_var }
      );
      if (response.status === 200) {
        this.ctl_segm_obj_var = response.data;
      }
      this.loadingSegmento = false;
    },

    async FetchCtlProductoFtn() {
      if (this.segm_obj_var != null) {
        this.loadingOBS = true;
        const response =
          await this.services.SolicitudCompra.getCtlObsBySegmento(
            this.id_proceso_compra_st,
            this.segm_obj_var.id
          );
        if (response.status === 200) {
          this.ctl_producto_var = response.data;
        }
        this.loadingOBS = false;
      }
    },

    async FetchCtlUnidadMedFtn() {
      const response = await this.services.Presentations.getListPresentations();
      if (response.status === 200) {
        this.ctl_unidad_med_var = response.data;
      }
    },

    // peticion de detalle de insumo
    async FetchDetailInsumo(id_insumo_param) {

      const response =
        await this.services.SolicitudCompra.getDetailInsumoSolicitudCompra(
          this.$route.params.id_solicitud,
          id_insumo_param
        );
      if (response.status === 200) {
        this.info_obs_var = response.data.detalle_obs;
        this.unidad_med_var = response.data.id_unidad_medida;
        this.cantidad_var =
          response.data.solicitud_compra_detalle_obs[0].cantidad;
        this.precio_unit_var = response.data.precio_unitario;
        this.$refs.money_txt_ref.updateValue(this.precio_unit_var);
        this.especificacion_var = response.data.observacion;
        this.id_detalle_var = response.data.solicitud_compra_detalle_obs[0].id;
        this.id_estado_insumo = response.data.id_estado;

        this.SNAPSHOT_CONST = {
          id_unidad_medida: this.unidad_med_var,
          cantidad: this.cantidad_var,
          precio_unitario: Number(this.precio_unit_var),
          especificacion: this.especificacion_var,
        };
      }

    },

    // peticiones de administracion de insumo
    async addInsumoFtn() {
      this.loadingSaveBtn = true;
      const body_param = {
        id_proceso_pac: Number(this.id_proceso_compra_st),
        id_obs: this.producto_var,
        id_unidad_medida: this.unidad_med_var,
        cantidad: Number(this.cantidad_var),
        precio_unitario: Number(this.precio_unit_var),
        especificacion: this.especificacion_var,
        id_solicitud_necesidad: this.segm_obj_var.id_solicitud_necesidad,
      };

      const response =
        await this.services.SolicitudCompra.postInsumoProcesoCompra(
          this.$route.params.id_solicitud,
          body_param
        ).catch(() => {
          this.loadingSaveBtn = false;
        });

      if (response && response.status === 201) {
        this.temporalAlert({
          message: "Insumo agregado correctamente",
          show: true,
          type: "success",
        });
        this.$emit("addItem", response.data);
        this.show_dialog = false;

        this.loadingSaveBtn = false;
      } else {
        this.temporalAlert({
          message: "Error al agregar insumo",
          show: true,
          type: "error",
        });

        this.loadingSaveBtn = false;
      }
    },

    async updateInsumoFtn() {
      this.loadingSaveBtn = true;
      const body_param = {
        id_unidad_medida: this.unidad_med_var,
        cantidad: Number(this.cantidad_var),
        precio_unitario: Number(this.precio_unit_var),
        especificacion: this.especificacion_var,
      };

      // comparar con snapshot con body_param
      const isEquals =
        JSON.stringify(this.SNAPSHOT_CONST) === JSON.stringify(body_param);
      if (isEquals) {
        this.temporalAlert({
          message: "No se han realizado cambios",
          show: true,
          type: "info",
        });
        return;
      } else {

        const response =
          await this.services.SolicitudCompra.putInsumoProcesoCompra(
            this.$route.params.id_solicitud,
            this.detailProp.id, // id_insumo
            this.id_detalle_var,
            body_param
          ).catch(() => ( this.loadingSaveBtn = false ));

        if (response.status === 200) {
          this.$emit("updateItem", response.data);
          this.temporalAlert({
            message: "Insumo actualizado correctamente",
            show: true,
            type: "success",
          });
          this.show_dialog = false;
          this.loadingSaveBtn = false;
        } else {
          this.temporalAlert({
            message: response.data.message || "Error al actualizar insumo",
            show: true,
            type: "error",
          });
        }
        this.loadingSaveBtn = false;

      }
    },

    async rejectInsumo() {

      const response = await this.services.SolicitudCompra.validacionInsumo(
        this.$route.params.id_solicitud,
        this.detailProp.id, // id_insumo
        {
          id_estado: 4,
          id_estado_actual: this.id_estado_insumo,
        }
      );

      if (response.status == 200) {
        this.$emit("changeItem", {
          id_insumo: this.detailProp.id,
          id_estado: 4,
        });
        this.show_dialog = false;
        this.temporalAlert({
          message: "Insumo actualizado correctamente",
          show: true,
          type: "success",
        });
      }

    },

    async allowInsumo() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.temporalAlert({
          message: "Por favor ingresar todos los datos requeridos",
          show: true,
          type: "error",
        });
        return;
      }


      const body_param = {
        id_unidad_medida: this.unidad_med_var,
        cantidad: Number(this.cantidad_var),
        precio_unitario: Number(this.precio_unit_var),
        especificacion: this.especificacion_var,
      };

      const responseUpdate =
        await this.services.SolicitudCompra.putInsumoProcesoCompra(
          this.$route.params.id_solicitud,
          this.detailProp.id, // id_insumo
          this.id_detalle_var,
          body_param
        );

      if (responseUpdate.status === 200) {
        const response = await this.services.SolicitudCompra.validacionInsumo(
          this.$route.params.id_solicitud,
          this.detailProp.id, // id_insumo
          {
            id_estado: 3,
            id_estado_actual: this.id_estado_insumo,
          }
        );

        if (response.status == 200) {
          this.$emit("changeItem", {
            id_insumo: this.detailProp.id,
            id_estado: 3,
          });
          this.show_dialog = false;
          this.temporalAlert({
            message: "Insumo actualizado correctamente",
            show: true,
            type: "success",
          });
        }
      }

    },
  },

  watch: {
    show_dialog(value) {
      if (!value) {
        this.CloseModalFtn();
      }
    },
  },

  mounted() {
    this.FetchCtlSegmObjFtn();
    this.FetchCtlUnidadMedFtn();
  },
};
</script>

<style>
</style>