<template>
  <div class="mt-9 mt-md-0">
    <v-tabs
      v-model="tab_value_var"
      color="secondary"
      show-arrows
      background-color="bgMinsal"
    >
      <v-tab
        v-for="(item, index) in OPTIONS_TAB_CONST"
        :key="index"
        class="text-no-style secondary--text"
      >
        <v-icon color="secondary"> {{ item.icon }} </v-icon>
        <span class="ml-2" v-text="item.text" />
      </v-tab>
    </v-tabs>
    <v-tabs-items :value="tab_value_var" style="width: 100%">
      <div v-for="(item, index) in OPTIONS_TAB_CONST" :key="index">
        <v-tab-item eager>
          <component class="pb-3 px-1" :ref="item.ref" :is="item.content" />
        </v-tab-item>
      </div>
    </v-tabs-items>
  </div>
</template>
  
<script>
// importaciones de componentes locales
import TabInsumoComponent from "./TabInsumoPTComponent.vue";
import AdministradorContratoComponent from "@/components/AdministradorContratoComponent.vue";
import PreciosMercadoComponent from "@/views/Solicitud/components/PreciosMercadoComponent.vue";
import DocumentosEspecificacionComponent from "./DocumentosEspecificacionCompoment.vue";

export default {
  components: {
    TabInsumoComponent,
    AdministradorContratoComponent,
    PreciosMercadoComponent,
    DocumentosEspecificacionComponent,
  },

  data: () => ({
    // variables
    tab_value_var: 0,
  }),
  computed: {
    OPTIONS_TAB_CONST() {
      if (this.haveRoles(["ROLE_JEFE_UNIDAD", "ROLE_OFICIAL_CUMPLIMIENTO", "ROLE_AUDITOR_INTERNO_AUIN"])) {
        return [
          {
            ref: "tab_insumo",
            content: TabInsumoComponent,
            icon: "mdi-package-variant-closed",
            text: "Insumos",
            value: 0,
          },
          {
            ref: "tab_administrador_contrato",
            content: AdministradorContratoComponent,
            icon: "mdi-account-multiple",
            text: "Administrador de contratos",
            value: 1,
          },
          {
            ref: "tab_precios_mercado",
            content: PreciosMercadoComponent,
            icon: "mdi-cash",
            text: "Precios de mercado",
            value: 2,
          },
          {
            ref: "tab_documentos_especificacion",
            content: DocumentosEspecificacionComponent,
            icon: "mdi-file-document-multiple-outline",
            text: "Documentos de especificación",
            value: 3,
          },
        ];
      } else if (this.haveRole("ROLE_TECNICO_UNIDAD")) {
        return [
          {
            ref: "tab_insumo",
            content: TabInsumoComponent,
            icon: "mdi-package-variant-closed",
            text: "Insumos",
            value: 0,
          },
          {
            ref: "tab_precios_mercado",
            content: PreciosMercadoComponent,
            icon: "mdi-cash",
            text: "Precios de mercado",
            value: 2,
          },
          {
            ref: "tab_documentos_especificacion",
            content: DocumentosEspecificacionComponent,
            icon: "mdi-file-document-multiple-outline",
            text: "Documentos de especificación",
            value: 3,
          },
        ];
      }
    },
  },
  methods: {
    StatusComponentFtn() {
      const stat_precio_mercado =
        this.$refs.tab_precios_mercado[0].cotizaciones_list;
      const stat_documentos =
        this.$refs.tab_documentos_especificacion[0].documentos;
      return {
        precio_mercado: stat_precio_mercado.length == 0 ? false : true,
        documentos: stat_documentos.length == 0 ? false : true,
      };
    },
  },
};
</script>