<template>
  <section class="mx-3">
    <!-- titulo -->
    <div class="my-6 d-flex flex-column text-center text-sm-left">
      <span
        class="text-h5 secondary--text"
        v-text="'Solicitudes obras, bienes y servicios'"
      />
      <span class="" v-text="'Ejercicio ' + anioFiscal" />
    </div>

    <!-- datos de la solicitud -->
    <v-row v-if="has_proceso_compra_var" class="mb-3">
      <v-col cols="12">
        <span class="text-h6">Código de solicitud: </span>
        <span class="text-h6 secondary--text">
          {{ solicitud.codigo }}
        </span>
      </v-col>
      <v-col cols="12">
        <span class="text-h6">Proceso de compra: </span>
        <span class="text-h6 secondary--text">
          {{ nombre_proceso_compra_var }}
        </span>
      </v-col>
    </v-row>

    <!-- formulario de nueva solicitud -->
    <v-row no-gutters v-else>
      <v-col cols="12" class="mt-6 mb-8" md="6">
        <v-autocomplete
          label="Proceso de compra"
          placeholder="Seleccione un proceso de compra"
          outlined
          hide-details
          v-model="id_proceso_compra_var"
          :items="ctl_proceso_compra_var"
          item-text="nombre_proceso"
          item-value="id"
          clearable
        />
      </v-col>
      <v-col cols="12" class="mt-6 mb-8 d-flex" md="6">
        <v-btn
          :disabled="id_proceso_compra_var == null"
          class="button-extra text-capitalize mt-3 ml-12"
          dark
          color="success"
          @click="AsignProcesoCompraFtn"
        >
          Asignar
        </v-btn>
      </v-col>
    </v-row>

    <!-- administracion de solicitud -->
    <v-row v-if="showActionButtons" class="mx-2 my-2">
      <v-col cols="12" class="px-0 d-flex inline">
        <div>
          <v-btn
            :disabled="habilitarAprobacion"
            @click="aprobarDialog = true"
            class="px-0"
            text
            color="green"
          >
            <v-icon color="green" small class="mr-2">mdi-send</v-icon>
            Aprobar solicitud
          </v-btn>
        </div>

        <v-btn
          @click="cancelarDialog = true"
          class="px-0 mx-5"
          text
          :disabled="habilitarCancelacion"
          color="red"
        >
          <v-icon small class="mr-2" color="red">mdi-cancel</v-icon>
          Cancelar solicitud
        </v-btn>
        <v-btn
          :disabled="habilitarObservacion"
          @click="observarDialog = true"
          text
          color="primary"
          class="px-0"
        >
          <v-icon color="primary-text" small class="mr-2"
            >mdi-message-reply-text</v-icon
          >
          Marcar con observación
        </v-btn>
      </v-col>
    </v-row>

    <!-- container -->
    <v-scroll-y-reverse-transition>
      <container-list-component
        ref="ref_container"
        v-if="id_proceso_compra_st != null"
      />
    </v-scroll-y-reverse-transition>

    <!-- botones de accion -->
    <footer class="py-4 mt-8 d-flex flex-column flex-sm-row" color="bgMinsal">
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.push({ name: 'list-solicitudes-compra' })"
      >
        Volver
      </v-btn>
      <v-btn
        v-if="
          showSendSolicitudAction()
        "
        class="button-extra text-capitalize mt-4 mt-sm-0 ml-4"
        color="secondary"
        @click="sendSolicitud"
      >

        {{ haveRole("ROLE_JEFE_UNIDAD") ? "Guardar" : "Enviar solicitud" }}
      </v-btn>
    </footer>

    <!-- chat -->
    <ChatComponent
      v-if="solicitud?.paso?.id == 2 || solicitud?.paso?.id == 8"
      :idSolicitud="$route.params.id_solicitud"
      modelo="SolicitudCompra"
    />

    <!-- modales de confirmacion -->
    <dialogoConfirmacion
      :show="aprobarDialog"
      title="¿Desea aprobar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="aprobarDialog = false"
      @confirm="changeSolicitudStatus('aprobar')"
    />
    <dialogoConfirmacion
      :show="cancelarDialog"
      title="¿Desea cancelar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="cancelarDialog = false"
      @confirm="changeSolicitudStatus('cancelar')"
    />
    <dialogoConfirmacion
      :show="observarDialog"
      title="Marcar con observación"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      showMotivoCancelar
      @input="setMotivo"
      @close="observarDialog = false"
      @confirm="changeSolicitudStatus('observar')"
    />
  </section>
</template>

<script>
// importacion de librerias
import { mapState, mapMutations } from "vuex";

// importacion de componentes locales
import ContainerListComponent from "./components/ContainerListPTComponent.vue";

// importacion de componentes globales
import ChatComponent from "@/components/ChatComponent";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import { forEach } from "lodash";

export default {
  components: {
    ContainerListComponent,
    ChatComponent,
    dialogoConfirmacion,
  },

  data: () => ({
    // formulario
    id_proceso_compra_var: null,
    nombre_proceso_compra_var: null,

    // modales
    aprobarDialog: false,
    cancelarDialog: false,
    observarDialog: false,

    // otros
    has_proceso_compra_var: false, // variable q indica si la solicitud tiene un proceso de compra asignado
    ctl_proceso_compra_var: [], // catalogo de procesos de compra
    motivo: "", // variable q guarda el motivo de la observacion (modal de observacion)
  }),

  computed: {
    // mapeo de variables del store
    ...mapState(["anioFiscal", "selectedUnidad", "sockets"]),
    ...mapState("SolicitudCompraStore/ProcesoTradicionalStore", [
      "id_proceso_compra_st",
      "solicitud",
      "list_insumos_st",
    ]),
    ...mapState("solicitudCompra", ["lista_administradores_de_contrato"]),
    ...mapState("agregarInsumo", ["id_solicitud"]),
    ...mapState(['institucionesUsuario']),
    ...mapState(["userInfo"]),
    // usuarioTieneAccesoInstitucionSolicitud() {

    //   if (!this.solicitud) return false;
    //   const idsInstitucionesUsuario = this.institucionesUsuario.map((i) => i.id);
    //   const institucionProcesoCompraPac = this.solicitud.proceso_compra_pac.id_institucion;
          
    //   return idsInstitucionesUsuario.includes(institucionProcesoCompraPac);
    // },
    habilitarAprobacion() {
      return (
        this.lista_administradores_de_contrato.length == 0 ||
        this.list_insumos_st.filter((target) => target.id_estado == 3).length ==
          0 ||
        this.solicitud?.paso?.id == 3
      );
    },

    habilitarCancelacion() {
      return (
        this.list_insumos_st.filter((target) => target.id_estado == 3).length >
        0
      );
    },

    habilitarObservacion() {
      return [8, 3].includes(this.solicitud?.paso?.id);
    },

    showActionButtons() {
      let validJefeUnidad;

      if (this.solicitud?.paso?.id == 2) {
        if (this.haveRole("ROLE_JEFE_UNIDAD")) {
          validJefeUnidad =
            this.solicitud?.usuario?.id != this.userInfo?.user?.id;
        }
      }

      return (
        this.haveRoles(["ROLE_JEFE_UNIDAD"]) &&
        validJefeUnidad &&
        this.solicitud?.proceso_compra_pac != null &&
        this.solicitud?.paso?.id != 3
      );
    },
  },

  methods: {
    ...mapMutations("SolicitudCompraStore/ProcesoTradicionalStore", [
      "SetIdProcesoCompraMt",
      "SetListInsumosMt",
      "ResetListInsumosMt",
      "ResetIdProcesoCompraMt",
      "SetSolicitud",
    ]),
    ...mapMutations("agregarInsumo", ["setIdSolicitud"]),

    // funciones regulares
    setMotivo(value) {
      this.motivo = value;
    },

    showSendSolicitudAction() {
      if (this.id_proceso_compra_st == null) return false;

      return (
        (this.haveRole("ROLE_TECNICO_UNIDAD") &&
          (this.solicitud?.paso == null ||
            this.solicitud?.paso?.id == 1 ||
            this.solicitud?.paso?.id == 8)) ||
        (this.haveRole("ROLE_JEFE_UNIDAD") && this.solicitud?.paso?.id == 2)
      );
    },

    // funciones asincronas
    async AsignProcesoCompraFtn() {
      const body_param = {
        id_proceso_pac: this.id_proceso_compra_var,
        id_unidad: this.selectedUnidad.id,
      };

      const response =
        await this.services.SolicitudCompra.putSolicitudProcesoCompra(
          this.$route.params.id_solicitud,
          body_param
        );
      if (response.status === 200) {
        this.temporalAlert({
          message: "Proceso de compra asignado correctamente",
          show: true,
          type: "success",
        });
        this.FetchDetailSolicitudCompra();
      }
    },

    async FetchCtlProcesoCompraFtn() {
      const response = await this.services.SolicitudCompra.getCtlProcesoCompra();
      this.ctl_proceso_compra_var = response.data;
      this.ctl_proceso_compra_var = response.data.map(proceso => ({
        ...proceso,
        nombre_proceso: `${proceso.ProcesoCompras[0].codigo_proceso}  -  ${proceso.nombre}`
      }));
    },

    async FetchDetailSolicitudCompra() {
      const response = await this.services.SolicitudCompra.getDetailSolicitudCompra(this.$route.params.id_solicitud);

      if (response.status === 200) {
        this.SetSolicitud(response.data);
        if (response.data.proceso_compra_pac != null) {
          this.has_proceso_compra_var = true;
          this.nombre_proceso_compra_var =
            response.data.proceso_compra_pac.nombre;
          this.SetIdProcesoCompraMt(response.data.proceso_compra_pac.id);
        } else {
          this.has_proceso_compra_var = false;
          await this.FetchCtlProcesoCompraFtn();
          this.ResetIdProcesoCompraMt();
        }

        const list_insumos = response.data.solicitud_compra_obs.map(
          (item, index) => {
            return {
              ...item,
              total:
                item.solicitud_compra_detalle_obs[0].cantidad *
                item.precio_unitario,
              num: index + 1,
            };
          }
        );

        this.setIdSolicitud(response.data.id);
        this.SetListInsumosMt(list_insumos);

      }
    },

    async sendSolicitud() {
      // HACK: se debe reestrucuturar los componentes y su info, para evitar utilizar un intermediario como el ref
      const invalid_data = this.$refs.ref_container.StatusComponentFtn();

      let insumosVerificados = true;

      this.list_insumos_st.forEach(insumo => {
        if (insumo.id_estado === 1) {
          insumosVerificados = false;
        }
      });

      if(insumosVerificados === false && (![1, 8].includes(this.solicitud.paso.id))){
        this.temporalAlert({
            message: "Hay insumos pendientes por verificar",
            show: true,
            type: "error",
          });
          return;
      }

      // verificacion del state del listado de insumos
      if (this.list_insumos_st.length === 0) {
        this.temporalAlert({
          message: "Debe agregar al menos un insumo",
          show: true,
          type: "error",
        });
        return;
      }

      // verificacion de el listado de precios atravez del ref
      if (!invalid_data.precio_mercado) {
        this.temporalAlert({
          message: "Debe agregar al menos un precio de mercado",
          show: true,
          type: "error",
        });
        return;
      }

      // verificacion de el listado de especificaciones atravez del ref
      if (!invalid_data.documentos) {
        this.temporalAlert({
          message: "Debe agregar al menos un documento de especificación",
          show: true,
          type: "error",
        });
        return;
      }


      if (!this.haveRole("ROLE_JEFE_UNIDAD")) {
        const param = {
          id_estado_solicitud: 1,
          id_solicitud_paso: 2,
          id_unidad: this.selectedUnidad.id,
          id_paso_actual: this.solicitud.paso.id,
        };
        const response =
          await this.services.SolicitudCompra.updateSolicitudEstado(
            this.$route.params.id_solicitud,
            param
          )
        if (response.status === 200) {
          this.temporalAlert({
            message: "Solicitud enviada correctamente",
            show: true,
            type: "success",
          });
          await this.FetchDetailSolicitudCompra();
          this.$router.push({ name: "list-solicitudes-compra" });

        }
      } else {
        const param2 = {
          id_estado_solicitud: 1,
          id_solicitud_paso: 3,
          id_unidad: this.selectedUnidad.id,
          id_paso_actual: this.solicitud.paso.id,
        };
        const response =
          await this.services.SolicitudCompra.updateSolicitudEstado(
            this.$route.params.id_solicitud,
            param2
          )
        if (response.status === 200) {
          this.temporalAlert({
            message: "Solicitud guardada correctamente",
            show: true,
            type: "success",
          });
          await this.FetchDetailSolicitudCompra();
          this.$router.push({ name: "list-solicitudes-compra" });

        }
      }
    },

    async changeSolicitudStatus(accion) {
      const { status } =
        await this.services.SolicitudCompra.updateSolicitudEstado(
          this.$route.params.id_solicitud,
          {
            id_unidad: this.selectedUnidad.id,
            id_solicitud_paso:
              accion == "aprobar" ? 3 : accion == "observar" ? 8 : 9,
            observacion: this.motivo,
            id_paso_actual: this.solicitud.paso.id,
          }
        );

      if (status == 200 || status == 204) {
        this.$router.back();
      }
    },
  },

  created() {
    this.FetchDetailSolicitudCompra();
  },

  beforeDestroy() {
    this.ResetListInsumosMt();
  },
};
</script>
